'use strict';

/**
 * Example:
 * var id = 'UsaJ7ZvCjmY';
 * document.querySelector('main').appendChild(makeYoutube(id));
 */
module.exports = function (id, cb, autoplay) {
	var options = [
		'autoplay=' + (autoplay ? '1' : '0'),
		'fs=0'
	].join('&');
	var src = 'http://www.youtube.com/embed/' + encodeURIComponent(id);
	
	var iframe = document.createElement('iframe');
	iframe.setAttribute('scrolling', 'no')
	iframe.setAttribute('frameborder', 'no')
	iframe.setAttribute('src', src + '?' + options);
	cb && iframe.addEventListener('load', cb);
	return iframe;
};
