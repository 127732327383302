'use strict';

var limber = require('limber');
var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makeSoundcloud = require('make-soundcloud');
var makeVideoBox = require('make-video-box');
var makePlaylistBox = require('make-playlist-box');

module.exports = function () {
	ajax.get('/api/featured', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.featuredTrack
				&& contentArea.appendChild(makeSoundcloud(data.featuredTrack.embedUri));
			data.featuredVideo
				&& contentArea.appendChild(makeVideoBox(data.featuredVideo));
			data.featuredPlaylist
				&& contentArea.appendChild(makePlaylistBox(data.featuredPlaylist));
			
			slideInChildren(contentArea);
		}
	});
};
