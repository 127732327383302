'use strict';

var limber = require('limber');

(function init() {
	limber.loader.progress = 67;
	window.onload = function () {
		limber.loader.progress = 100;
	};
	
	if (document.body.classList.contains('home')) {
		require('home')();
	}
	if (document.body.classList.contains('contact')) {
		require('contact')();
	}
	if (document.body.classList.contains('music')) {
		require('music')();
	}
	if (document.body.classList.contains('sound-design')) {
		require('sound-design')();
	}
	
	function exitPopup(popup) {
		if (popup.classList.contains('active')) {
			popup.classList.remove('active');
			document.querySelector('#container').classList.remove('blur');
			while (popup.firstElementChild) popup.removeChild(popup.firstElementChild);
		}
	}
	document.querySelector('#popup').addEventListener('click', function (ev) {
		ev.target === this && exitPopup(this);
	});
	document.addEventListener('keydown', function (ev) {
		ev.keyCode == 27 && exitPopup(document.querySelector('#popup'));
	});
	
	var snapTransitions = (function (document, Array) {
		function getStyles() {
			var els = document.querySelectorAll('*');
			var len = els.length;
			var styles = new Array(len);
			for (var i=0; i<len; i++) styles[i] = els[i].style;
			return styles;
		}
		function getTransition(style) {
			return style.transition;
		}
		return function () {
			var styles = getStyles();
			var transitions = styles.map(getTransition);
			var len = styles.length;
			for (var i=0; i<len; i++) styles[i].transition = 'none';
			document.body.offsetHeight; // force reflow
			for (var i=0; i<len; i++) styles[i].transition = transitions[i];
		};
	}(document, Array));
	
	function positionSocialNav() {
		var frame = document.querySelector('#frame');
		var main = document.querySelector('main');
		var section = main.querySelector('section');
		var socialNav = main.querySelector('nav.social-nav');
		var backNav = document.querySelector('nav.back');
		
		var combinedSize = section.scrollHeight + main.offsetTop*2 + socialNav.offsetHeight;
		if (backNav) {
			combinedSize += backNav.offsetHeight;
		}
		
		if (limber.mq.booklet && combinedSize <= frame.clientHeight) {
			socialNav.style.position = 'fixed';
			socialNav.style.left = frame.offsetLeft + main.offsetLeft + 'px';
			socialNav.style.bottom = frame.offsetTop + main.offsetTop + 'px';
		} else {
			socialNav.style.position = '';
			socialNav.style.left = '';
			socialNav.style.bottom = '';
		}
	}
	
	function resize() {
		snapTransitions();
		positionSocialNav();
	}
	
	window.addEventListener('resize', resize);
	resize();
	
}());
