'use strict';

var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makeVideoBox = require('make-video-box');

module.exports = function () {
	ajax.get('/api/sound-design', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.videos.forEach(function (video) {
				contentArea.appendChild(makeVideoBox(video));
			});
			
			slideInChildren(contentArea);
		}
	});
};
