'use strict';

module.exports = function (text) {
	return escapeHtml(text
		.replace(/\ufffd/g, '')
		.replace(/\b[-\w.]+@\w[-\w.]*?\.(co\.uk|com|net|co|org|int|edu|mil|gov|us|me|io|uk|ninja|be|gl|do|tv|cc|de|es|eu|fr|gb|gg|gy|ie|is|it|jp|kr|mt|my|na|no|pe|pm|so|ss|to|ca|mx)\b/gi,
			function (match) {
				return '\ufffd<a href="mailto:' + encodeURI(match) + '">' + escapeHtml(match) + '</a>\ufffd';
			})
		.replace(/\ufffd[\s\S]*?\ufffd|\b(https?:\/\/)?(\w[-\w.]*?\.(co\.uk|com|net|co|org|int|edu|mil|gov|us|me|io|uk|ninja|be|gl|do|tv|cc|de|es|eu|fr|gb|gg|gy|ie|is|it|jp|kr|mt|my|na|no|pe|pm|so|ss|to|ca|mx)\b((\/|\?)(\.?[-\/\w?&=+!~#$%^])*)?)/gi,
			function (match, p1, p2) {
				if (match[0] === '\ufffd') {
					return match;
				}
				var href = (p1 || 'http://') + p2;
				return '\ufffd<a href="' + encodeURI(href) + '">' + escapeHtml(match) + '</a>\ufffd';
			}));
};

function escapeHtml(text) {
	return text.replace(/\ufffd[\s\S]*?\ufffd|<|>|&|'|"/g, function (match) {
		if (match[0] === '\ufffd') {
			return match.replace(/\ufffd/g, '');
		}
		switch (match.charCodeAt(0)) {
			case 34: return '&quot;';	// "
			case 38: return '&amp;';	// &
			case 39: return '&#39;';	// '
			case 60: return '&lt;';		// <
			case 62: return '&gt;';		// >
		}
		return '';
	});
}
