(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

module.exports = {
	name: /[\w]/,
	email: /^[^@]+@[^.@]+\.[^@]+$/,
	message: /[\w]/
};

},{}],2:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

function EventEmitter() {
  this._events = this._events || {};
  this._maxListeners = this._maxListeners || undefined;
}
module.exports = EventEmitter;

// Backwards-compat with node 0.10.x
EventEmitter.EventEmitter = EventEmitter;

EventEmitter.prototype._events = undefined;
EventEmitter.prototype._maxListeners = undefined;

// By default EventEmitters will print a warning if more than 10 listeners are
// added to it. This is a useful default which helps finding memory leaks.
EventEmitter.defaultMaxListeners = 10;

// Obviously not all Emitters should be limited to 10. This function allows
// that to be increased. Set to zero for unlimited.
EventEmitter.prototype.setMaxListeners = function(n) {
  if (!isNumber(n) || n < 0 || isNaN(n))
    throw TypeError('n must be a positive number');
  this._maxListeners = n;
  return this;
};

EventEmitter.prototype.emit = function(type) {
  var er, handler, len, args, i, listeners;

  if (!this._events)
    this._events = {};

  // If there is no 'error' event listener then throw.
  if (type === 'error') {
    if (!this._events.error ||
        (isObject(this._events.error) && !this._events.error.length)) {
      er = arguments[1];
      if (er instanceof Error) {
        throw er; // Unhandled 'error' event
      }
      throw TypeError('Uncaught, unspecified "error" event.');
    }
  }

  handler = this._events[type];

  if (isUndefined(handler))
    return false;

  if (isFunction(handler)) {
    switch (arguments.length) {
      // fast cases
      case 1:
        handler.call(this);
        break;
      case 2:
        handler.call(this, arguments[1]);
        break;
      case 3:
        handler.call(this, arguments[1], arguments[2]);
        break;
      // slower
      default:
        len = arguments.length;
        args = new Array(len - 1);
        for (i = 1; i < len; i++)
          args[i - 1] = arguments[i];
        handler.apply(this, args);
    }
  } else if (isObject(handler)) {
    len = arguments.length;
    args = new Array(len - 1);
    for (i = 1; i < len; i++)
      args[i - 1] = arguments[i];

    listeners = handler.slice();
    len = listeners.length;
    for (i = 0; i < len; i++)
      listeners[i].apply(this, args);
  }

  return true;
};

EventEmitter.prototype.addListener = function(type, listener) {
  var m;

  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  if (!this._events)
    this._events = {};

  // To avoid recursion in the case that type === "newListener"! Before
  // adding it to the listeners, first emit "newListener".
  if (this._events.newListener)
    this.emit('newListener', type,
              isFunction(listener.listener) ?
              listener.listener : listener);

  if (!this._events[type])
    // Optimize the case of one listener. Don't need the extra array object.
    this._events[type] = listener;
  else if (isObject(this._events[type]))
    // If we've already got an array, just append.
    this._events[type].push(listener);
  else
    // Adding the second element, need to change to array.
    this._events[type] = [this._events[type], listener];

  // Check for listener leak
  if (isObject(this._events[type]) && !this._events[type].warned) {
    var m;
    if (!isUndefined(this._maxListeners)) {
      m = this._maxListeners;
    } else {
      m = EventEmitter.defaultMaxListeners;
    }

    if (m && m > 0 && this._events[type].length > m) {
      this._events[type].warned = true;
      console.error('(node) warning: possible EventEmitter memory ' +
                    'leak detected. %d listeners added. ' +
                    'Use emitter.setMaxListeners() to increase limit.',
                    this._events[type].length);
      if (typeof console.trace === 'function') {
        // not supported in IE 10
        console.trace();
      }
    }
  }

  return this;
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.once = function(type, listener) {
  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  var fired = false;

  function g() {
    this.removeListener(type, g);

    if (!fired) {
      fired = true;
      listener.apply(this, arguments);
    }
  }

  g.listener = listener;
  this.on(type, g);

  return this;
};

// emits a 'removeListener' event iff the listener was removed
EventEmitter.prototype.removeListener = function(type, listener) {
  var list, position, length, i;

  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  if (!this._events || !this._events[type])
    return this;

  list = this._events[type];
  length = list.length;
  position = -1;

  if (list === listener ||
      (isFunction(list.listener) && list.listener === listener)) {
    delete this._events[type];
    if (this._events.removeListener)
      this.emit('removeListener', type, listener);

  } else if (isObject(list)) {
    for (i = length; i-- > 0;) {
      if (list[i] === listener ||
          (list[i].listener && list[i].listener === listener)) {
        position = i;
        break;
      }
    }

    if (position < 0)
      return this;

    if (list.length === 1) {
      list.length = 0;
      delete this._events[type];
    } else {
      list.splice(position, 1);
    }

    if (this._events.removeListener)
      this.emit('removeListener', type, listener);
  }

  return this;
};

EventEmitter.prototype.removeAllListeners = function(type) {
  var key, listeners;

  if (!this._events)
    return this;

  // not listening for removeListener, no need to emit
  if (!this._events.removeListener) {
    if (arguments.length === 0)
      this._events = {};
    else if (this._events[type])
      delete this._events[type];
    return this;
  }

  // emit removeListener for all listeners on all events
  if (arguments.length === 0) {
    for (key in this._events) {
      if (key === 'removeListener') continue;
      this.removeAllListeners(key);
    }
    this.removeAllListeners('removeListener');
    this._events = {};
    return this;
  }

  listeners = this._events[type];

  if (isFunction(listeners)) {
    this.removeListener(type, listeners);
  } else {
    // LIFO order
    while (listeners.length)
      this.removeListener(type, listeners[listeners.length - 1]);
  }
  delete this._events[type];

  return this;
};

EventEmitter.prototype.listeners = function(type) {
  var ret;
  if (!this._events || !this._events[type])
    ret = [];
  else if (isFunction(this._events[type]))
    ret = [this._events[type]];
  else
    ret = this._events[type].slice();
  return ret;
};

EventEmitter.listenerCount = function(emitter, type) {
  var ret;
  if (!emitter._events || !emitter._events[type])
    ret = 0;
  else if (isFunction(emitter._events[type]))
    ret = 1;
  else
    ret = emitter._events[type].length;
  return ret;
};

function isFunction(arg) {
  return typeof arg === 'function';
}

function isNumber(arg) {
  return typeof arg === 'number';
}

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}

function isUndefined(arg) {
  return arg === void 0;
}

},{}],3:[function(require,module,exports){
// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

function defaultSetTimout() {
    throw new Error('setTimeout has not been defined');
}
function defaultClearTimeout () {
    throw new Error('clearTimeout has not been defined');
}
(function () {
    try {
        if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
        } else {
            cachedSetTimeout = defaultSetTimout;
        }
    } catch (e) {
        cachedSetTimeout = defaultSetTimout;
    }
    try {
        if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
        } else {
            cachedClearTimeout = defaultClearTimeout;
        }
    } catch (e) {
        cachedClearTimeout = defaultClearTimeout;
    }
} ())
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        //normal enviroments in sane situations
        return setTimeout(fun, 0);
    }
    // if setTimeout wasn't available but was latter defined
    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
        cachedSetTimeout = setTimeout;
        return setTimeout(fun, 0);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedSetTimeout(fun, 0);
    } catch(e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
        } catch(e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
        }
    }


}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        //normal enviroments in sane situations
        return clearTimeout(marker);
    }
    // if clearTimeout wasn't available but was latter defined
    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
        cachedClearTimeout = clearTimeout;
        return clearTimeout(marker);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedClearTimeout(marker);
    } catch (e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
        } catch (e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
        }
    }



}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while(len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;
process.prependListener = noop;
process.prependOnceListener = noop;

process.listeners = function (name) { return [] }

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function() { return 0; };

},{}],4:[function(require,module,exports){
if (typeof Object.create === 'function') {
  // implementation from standard node.js 'util' module
  module.exports = function inherits(ctor, superCtor) {
    ctor.super_ = superCtor
    ctor.prototype = Object.create(superCtor.prototype, {
      constructor: {
        value: ctor,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
  };
} else {
  // old school shim for old browsers
  module.exports = function inherits(ctor, superCtor) {
    ctor.super_ = superCtor
    var TempCtor = function () {}
    TempCtor.prototype = superCtor.prototype
    ctor.prototype = new TempCtor()
    ctor.prototype.constructor = ctor
  }
}

},{}],5:[function(require,module,exports){
module.exports = function isBuffer(arg) {
  return arg && typeof arg === 'object'
    && typeof arg.copy === 'function'
    && typeof arg.fill === 'function'
    && typeof arg.readUInt8 === 'function';
}
},{}],6:[function(require,module,exports){
(function (process,global){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

var formatRegExp = /%[sdj%]/g;
exports.format = function(f) {
  if (!isString(f)) {
    var objects = [];
    for (var i = 0; i < arguments.length; i++) {
      objects.push(inspect(arguments[i]));
    }
    return objects.join(' ');
  }

  var i = 1;
  var args = arguments;
  var len = args.length;
  var str = String(f).replace(formatRegExp, function(x) {
    if (x === '%%') return '%';
    if (i >= len) return x;
    switch (x) {
      case '%s': return String(args[i++]);
      case '%d': return Number(args[i++]);
      case '%j':
        try {
          return JSON.stringify(args[i++]);
        } catch (_) {
          return '[Circular]';
        }
      default:
        return x;
    }
  });
  for (var x = args[i]; i < len; x = args[++i]) {
    if (isNull(x) || !isObject(x)) {
      str += ' ' + x;
    } else {
      str += ' ' + inspect(x);
    }
  }
  return str;
};


// Mark that a method should not be used.
// Returns a modified function which warns once by default.
// If --no-deprecation is set, then it is a no-op.
exports.deprecate = function(fn, msg) {
  // Allow for deprecating things in the process of starting up.
  if (isUndefined(global.process)) {
    return function() {
      return exports.deprecate(fn, msg).apply(this, arguments);
    };
  }

  if (process.noDeprecation === true) {
    return fn;
  }

  var warned = false;
  function deprecated() {
    if (!warned) {
      if (process.throwDeprecation) {
        throw new Error(msg);
      } else if (process.traceDeprecation) {
        console.trace(msg);
      } else {
        console.error(msg);
      }
      warned = true;
    }
    return fn.apply(this, arguments);
  }

  return deprecated;
};


var debugs = {};
var debugEnviron;
exports.debuglog = function(set) {
  if (isUndefined(debugEnviron))
    debugEnviron = process.env.NODE_DEBUG || '';
  set = set.toUpperCase();
  if (!debugs[set]) {
    if (new RegExp('\\b' + set + '\\b', 'i').test(debugEnviron)) {
      var pid = process.pid;
      debugs[set] = function() {
        var msg = exports.format.apply(exports, arguments);
        console.error('%s %d: %s', set, pid, msg);
      };
    } else {
      debugs[set] = function() {};
    }
  }
  return debugs[set];
};


/**
 * Echos the value of a value. Trys to print the value out
 * in the best way possible given the different types.
 *
 * @param {Object} obj The object to print out.
 * @param {Object} opts Optional options object that alters the output.
 */
/* legacy: obj, showHidden, depth, colors*/
function inspect(obj, opts) {
  // default options
  var ctx = {
    seen: [],
    stylize: stylizeNoColor
  };
  // legacy...
  if (arguments.length >= 3) ctx.depth = arguments[2];
  if (arguments.length >= 4) ctx.colors = arguments[3];
  if (isBoolean(opts)) {
    // legacy...
    ctx.showHidden = opts;
  } else if (opts) {
    // got an "options" object
    exports._extend(ctx, opts);
  }
  // set default options
  if (isUndefined(ctx.showHidden)) ctx.showHidden = false;
  if (isUndefined(ctx.depth)) ctx.depth = 2;
  if (isUndefined(ctx.colors)) ctx.colors = false;
  if (isUndefined(ctx.customInspect)) ctx.customInspect = true;
  if (ctx.colors) ctx.stylize = stylizeWithColor;
  return formatValue(ctx, obj, ctx.depth);
}
exports.inspect = inspect;


// http://en.wikipedia.org/wiki/ANSI_escape_code#graphics
inspect.colors = {
  'bold' : [1, 22],
  'italic' : [3, 23],
  'underline' : [4, 24],
  'inverse' : [7, 27],
  'white' : [37, 39],
  'grey' : [90, 39],
  'black' : [30, 39],
  'blue' : [34, 39],
  'cyan' : [36, 39],
  'green' : [32, 39],
  'magenta' : [35, 39],
  'red' : [31, 39],
  'yellow' : [33, 39]
};

// Don't use 'blue' not visible on cmd.exe
inspect.styles = {
  'special': 'cyan',
  'number': 'yellow',
  'boolean': 'yellow',
  'undefined': 'grey',
  'null': 'bold',
  'string': 'green',
  'date': 'magenta',
  // "name": intentionally not styling
  'regexp': 'red'
};


function stylizeWithColor(str, styleType) {
  var style = inspect.styles[styleType];

  if (style) {
    return '\u001b[' + inspect.colors[style][0] + 'm' + str +
           '\u001b[' + inspect.colors[style][1] + 'm';
  } else {
    return str;
  }
}


function stylizeNoColor(str, styleType) {
  return str;
}


function arrayToHash(array) {
  var hash = {};

  array.forEach(function(val, idx) {
    hash[val] = true;
  });

  return hash;
}


function formatValue(ctx, value, recurseTimes) {
  // Provide a hook for user-specified inspect functions.
  // Check that value is an object with an inspect function on it
  if (ctx.customInspect &&
      value &&
      isFunction(value.inspect) &&
      // Filter out the util module, it's inspect function is special
      value.inspect !== exports.inspect &&
      // Also filter out any prototype objects using the circular check.
      !(value.constructor && value.constructor.prototype === value)) {
    var ret = value.inspect(recurseTimes, ctx);
    if (!isString(ret)) {
      ret = formatValue(ctx, ret, recurseTimes);
    }
    return ret;
  }

  // Primitive types cannot have properties
  var primitive = formatPrimitive(ctx, value);
  if (primitive) {
    return primitive;
  }

  // Look up the keys of the object.
  var keys = Object.keys(value);
  var visibleKeys = arrayToHash(keys);

  if (ctx.showHidden) {
    keys = Object.getOwnPropertyNames(value);
  }

  // IE doesn't make error fields non-enumerable
  // http://msdn.microsoft.com/en-us/library/ie/dww52sbt(v=vs.94).aspx
  if (isError(value)
      && (keys.indexOf('message') >= 0 || keys.indexOf('description') >= 0)) {
    return formatError(value);
  }

  // Some type of object without properties can be shortcutted.
  if (keys.length === 0) {
    if (isFunction(value)) {
      var name = value.name ? ': ' + value.name : '';
      return ctx.stylize('[Function' + name + ']', 'special');
    }
    if (isRegExp(value)) {
      return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
    }
    if (isDate(value)) {
      return ctx.stylize(Date.prototype.toString.call(value), 'date');
    }
    if (isError(value)) {
      return formatError(value);
    }
  }

  var base = '', array = false, braces = ['{', '}'];

  // Make Array say that they are Array
  if (isArray(value)) {
    array = true;
    braces = ['[', ']'];
  }

  // Make functions say that they are functions
  if (isFunction(value)) {
    var n = value.name ? ': ' + value.name : '';
    base = ' [Function' + n + ']';
  }

  // Make RegExps say that they are RegExps
  if (isRegExp(value)) {
    base = ' ' + RegExp.prototype.toString.call(value);
  }

  // Make dates with properties first say the date
  if (isDate(value)) {
    base = ' ' + Date.prototype.toUTCString.call(value);
  }

  // Make error with message first say the error
  if (isError(value)) {
    base = ' ' + formatError(value);
  }

  if (keys.length === 0 && (!array || value.length == 0)) {
    return braces[0] + base + braces[1];
  }

  if (recurseTimes < 0) {
    if (isRegExp(value)) {
      return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
    } else {
      return ctx.stylize('[Object]', 'special');
    }
  }

  ctx.seen.push(value);

  var output;
  if (array) {
    output = formatArray(ctx, value, recurseTimes, visibleKeys, keys);
  } else {
    output = keys.map(function(key) {
      return formatProperty(ctx, value, recurseTimes, visibleKeys, key, array);
    });
  }

  ctx.seen.pop();

  return reduceToSingleString(output, base, braces);
}


function formatPrimitive(ctx, value) {
  if (isUndefined(value))
    return ctx.stylize('undefined', 'undefined');
  if (isString(value)) {
    var simple = '\'' + JSON.stringify(value).replace(/^"|"$/g, '')
                                             .replace(/'/g, "\\'")
                                             .replace(/\\"/g, '"') + '\'';
    return ctx.stylize(simple, 'string');
  }
  if (isNumber(value))
    return ctx.stylize('' + value, 'number');
  if (isBoolean(value))
    return ctx.stylize('' + value, 'boolean');
  // For some reason typeof null is "object", so special case here.
  if (isNull(value))
    return ctx.stylize('null', 'null');
}


function formatError(value) {
  return '[' + Error.prototype.toString.call(value) + ']';
}


function formatArray(ctx, value, recurseTimes, visibleKeys, keys) {
  var output = [];
  for (var i = 0, l = value.length; i < l; ++i) {
    if (hasOwnProperty(value, String(i))) {
      output.push(formatProperty(ctx, value, recurseTimes, visibleKeys,
          String(i), true));
    } else {
      output.push('');
    }
  }
  keys.forEach(function(key) {
    if (!key.match(/^\d+$/)) {
      output.push(formatProperty(ctx, value, recurseTimes, visibleKeys,
          key, true));
    }
  });
  return output;
}


function formatProperty(ctx, value, recurseTimes, visibleKeys, key, array) {
  var name, str, desc;
  desc = Object.getOwnPropertyDescriptor(value, key) || { value: value[key] };
  if (desc.get) {
    if (desc.set) {
      str = ctx.stylize('[Getter/Setter]', 'special');
    } else {
      str = ctx.stylize('[Getter]', 'special');
    }
  } else {
    if (desc.set) {
      str = ctx.stylize('[Setter]', 'special');
    }
  }
  if (!hasOwnProperty(visibleKeys, key)) {
    name = '[' + key + ']';
  }
  if (!str) {
    if (ctx.seen.indexOf(desc.value) < 0) {
      if (isNull(recurseTimes)) {
        str = formatValue(ctx, desc.value, null);
      } else {
        str = formatValue(ctx, desc.value, recurseTimes - 1);
      }
      if (str.indexOf('\n') > -1) {
        if (array) {
          str = str.split('\n').map(function(line) {
            return '  ' + line;
          }).join('\n').substr(2);
        } else {
          str = '\n' + str.split('\n').map(function(line) {
            return '   ' + line;
          }).join('\n');
        }
      }
    } else {
      str = ctx.stylize('[Circular]', 'special');
    }
  }
  if (isUndefined(name)) {
    if (array && key.match(/^\d+$/)) {
      return str;
    }
    name = JSON.stringify('' + key);
    if (name.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/)) {
      name = name.substr(1, name.length - 2);
      name = ctx.stylize(name, 'name');
    } else {
      name = name.replace(/'/g, "\\'")
                 .replace(/\\"/g, '"')
                 .replace(/(^"|"$)/g, "'");
      name = ctx.stylize(name, 'string');
    }
  }

  return name + ': ' + str;
}


function reduceToSingleString(output, base, braces) {
  var numLinesEst = 0;
  var length = output.reduce(function(prev, cur) {
    numLinesEst++;
    if (cur.indexOf('\n') >= 0) numLinesEst++;
    return prev + cur.replace(/\u001b\[\d\d?m/g, '').length + 1;
  }, 0);

  if (length > 60) {
    return braces[0] +
           (base === '' ? '' : base + '\n ') +
           ' ' +
           output.join(',\n  ') +
           ' ' +
           braces[1];
  }

  return braces[0] + base + ' ' + output.join(', ') + ' ' + braces[1];
}


// NOTE: These type checking functions intentionally don't use `instanceof`
// because it is fragile and can be easily faked with `Object.create()`.
function isArray(ar) {
  return Array.isArray(ar);
}
exports.isArray = isArray;

function isBoolean(arg) {
  return typeof arg === 'boolean';
}
exports.isBoolean = isBoolean;

function isNull(arg) {
  return arg === null;
}
exports.isNull = isNull;

function isNullOrUndefined(arg) {
  return arg == null;
}
exports.isNullOrUndefined = isNullOrUndefined;

function isNumber(arg) {
  return typeof arg === 'number';
}
exports.isNumber = isNumber;

function isString(arg) {
  return typeof arg === 'string';
}
exports.isString = isString;

function isSymbol(arg) {
  return typeof arg === 'symbol';
}
exports.isSymbol = isSymbol;

function isUndefined(arg) {
  return arg === void 0;
}
exports.isUndefined = isUndefined;

function isRegExp(re) {
  return isObject(re) && objectToString(re) === '[object RegExp]';
}
exports.isRegExp = isRegExp;

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}
exports.isObject = isObject;

function isDate(d) {
  return isObject(d) && objectToString(d) === '[object Date]';
}
exports.isDate = isDate;

function isError(e) {
  return isObject(e) &&
      (objectToString(e) === '[object Error]' || e instanceof Error);
}
exports.isError = isError;

function isFunction(arg) {
  return typeof arg === 'function';
}
exports.isFunction = isFunction;

function isPrimitive(arg) {
  return arg === null ||
         typeof arg === 'boolean' ||
         typeof arg === 'number' ||
         typeof arg === 'string' ||
         typeof arg === 'symbol' ||  // ES6 symbol
         typeof arg === 'undefined';
}
exports.isPrimitive = isPrimitive;

exports.isBuffer = require('./support/isBuffer');

function objectToString(o) {
  return Object.prototype.toString.call(o);
}


function pad(n) {
  return n < 10 ? '0' + n.toString(10) : n.toString(10);
}


var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
              'Oct', 'Nov', 'Dec'];

// 26 Feb 16:19:34
function timestamp() {
  var d = new Date();
  var time = [pad(d.getHours()),
              pad(d.getMinutes()),
              pad(d.getSeconds())].join(':');
  return [d.getDate(), months[d.getMonth()], time].join(' ');
}


// log is just a thin wrapper to console.log that prepends a timestamp
exports.log = function() {
  console.log('%s - %s', timestamp(), exports.format.apply(exports, arguments));
};


/**
 * Inherit the prototype methods from one constructor into another.
 *
 * The Function.prototype.inherits from lang.js rewritten as a standalone
 * function (not on Function.prototype). NOTE: If this file is to be loaded
 * during bootstrapping this function needs to be rewritten using some native
 * functions as prototype setup using normal JavaScript does not work as
 * expected during bootstrapping (see mirror.js in r114903).
 *
 * @param {function} ctor Constructor function which needs to inherit the
 *     prototype.
 * @param {function} superCtor Constructor function to inherit prototype from.
 */
exports.inherits = require('inherits');

exports._extend = function(origin, add) {
  // Don't do anything if add isn't an object
  if (!add || !isObject(add)) return origin;

  var keys = Object.keys(add);
  var i = keys.length;
  while (i--) {
    origin[keys[i]] = add[keys[i]];
  }
  return origin;
};

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

}).call(this,require('_process'),typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./support/isBuffer":5,"_process":3,"inherits":4}],7:[function(require,module,exports){
'use strict';

var limber = require('limber');

(function init() {
	limber.loader.progress = 67;
	window.onload = function () {
		limber.loader.progress = 100;
	};
	
	if (document.body.classList.contains('home')) {
		require('home')();
	}
	if (document.body.classList.contains('contact')) {
		require('contact')();
	}
	if (document.body.classList.contains('music')) {
		require('music')();
	}
	if (document.body.classList.contains('sound-design')) {
		require('sound-design')();
	}
	
	function exitPopup(popup) {
		if (popup.classList.contains('active')) {
			popup.classList.remove('active');
			document.querySelector('#container').classList.remove('blur');
			while (popup.firstElementChild) popup.removeChild(popup.firstElementChild);
		}
	}
	document.querySelector('#popup').addEventListener('click', function (ev) {
		ev.target === this && exitPopup(this);
	});
	document.addEventListener('keydown', function (ev) {
		ev.keyCode == 27 && exitPopup(document.querySelector('#popup'));
	});
	
	var snapTransitions = (function (document, Array) {
		function getStyles() {
			var els = document.querySelectorAll('*');
			var len = els.length;
			var styles = new Array(len);
			for (var i=0; i<len; i++) styles[i] = els[i].style;
			return styles;
		}
		function getTransition(style) {
			return style.transition;
		}
		return function () {
			var styles = getStyles();
			var transitions = styles.map(getTransition);
			var len = styles.length;
			for (var i=0; i<len; i++) styles[i].transition = 'none';
			document.body.offsetHeight; // force reflow
			for (var i=0; i<len; i++) styles[i].transition = transitions[i];
		};
	}(document, Array));
	
	function positionSocialNav() {
		var frame = document.querySelector('#frame');
		var main = document.querySelector('main');
		var section = main.querySelector('section');
		var socialNav = main.querySelector('nav.social-nav');
		var backNav = document.querySelector('nav.back');
		
		var combinedSize = section.scrollHeight + main.offsetTop*2 + socialNav.offsetHeight;
		if (backNav) {
			combinedSize += backNav.offsetHeight;
		}
		
		if (limber.mq.booklet && combinedSize <= frame.clientHeight) {
			socialNav.style.position = 'fixed';
			socialNav.style.left = frame.offsetLeft + main.offsetLeft + 'px';
			socialNav.style.bottom = frame.offsetTop + main.offsetTop + 'px';
		} else {
			socialNav.style.position = '';
			socialNav.style.left = '';
			socialNav.style.bottom = '';
		}
	}
	
	function resize() {
		snapTransitions();
		positionSocialNav();
	}
	
	window.addEventListener('resize', resize);
	resize();
	
}());

},{"contact":14,"home":15,"limber":8,"music":21,"sound-design":25}],8:[function(require,module,exports){
'use strict';
module.exports = require('./src/limber');

},{"./src/limber":12}],9:[function(require,module,exports){
'use strict';

var document = window.document;
var getComputedStyle = window.getComputedStyle;

module.exports = {
	__getStylVariable: function (variableName) {
		var el = document.createElement('meta');
		el.className = 'limber-var-' + variableName;
		document.head.appendChild(el);
		var value = getComputedStyle(el).fontFamily.replace(/^['"](.*)['"]$/, '$1');
		document.head.removeChild(el);
		return value;
	}
};

},{}],10:[function(require,module,exports){
'use strict';

var util = require('util');
var EventEmitter = require('events');
var document = window.document;
var Math = window.Math;

module.exports = function (Limber) {
	var progressUnit = Limber.__getStylVariable('loader-progress-unit');
	Limber.loader = new Loader(progressUnit);
};

function Loader(progressUnit) {
	EventEmitter.call(this);
	
	var progress = 0;
	Object.defineProperty(this, 'progress', {
		get: function () {return progress;},
		set: function (val) {
			var previous = progress;
			progress = Math.min(Math.max(+val || 0, 0), 100);
			
			if (progress < 100 && previous === 100) {
				updateProgressBar(true);
				this.emit('loading');
			}
			this.emit('progress', previous, progress);
		}
	});
	
	this.on('progress', function (previous) {
		if (previous == null) previous = progress;
		updateProgressBar();
		progress === 100 && previous < 100 && this.emit('load');
	});
	this.on('load', setLoaded.bind(this, true));
	this.on('loading', setLoaded.bind(this, false));
	
	function updateProgressBar(reflow) {
		var progressEl = document.getElementById('limber-progress');
		if (progressEl) {
			progressEl.style.fontSize = progress + progressUnit;
			reflow && progressEl.offsetHeight;
		}
	}
	function setLoaded(loaded) {
		var loaderEl = document.getElementById('limber-loader');
		loaderEl && loaderEl.classList[loaded ? 'add' : 'remove']('loaded');
	}
}
util.inherits(Loader, EventEmitter);

},{"events":2,"util":6}],11:[function(require,module,exports){
'use strict';

var matchMedia = window.matchMedia;

module.exports = function (Limber) {
	Limber.mq = {};
	
	var queryStrings = {
		phone: Limber.__getStylVariable('phone'),
		tablet: Limber.__getStylVariable('tablet'),
		desktop: Limber.__getStylVariable('desktop'),
		mobile: Limber.__getStylVariable('mobile'),
		booklet: Limber.__getStylVariable('booklet'),
		viewportMin: Limber.__getStylVariable('viewport-min'),
		viewportMax: Limber.__getStylVariable('viewport-max'),
		retina: Limber.__getStylVariable('retina')
	};
	
	function update() {
		if (Limber.mq && typeof Limber.mq === 'object') {
			for (var key in queryStrings) Limber.mq[key] = !!matchMedia(queryStrings[key]).matches;
		}
	}
	
	update();
	setTimeout(update, 0);
	window.addEventListener('resize', update);
};

},{}],12:[function(require,module,exports){
'use strict';

var Limber = module.exports = require('./js/core');

require('./js/template')(Limber);
require('./js/loader')(Limber);

},{"./js/core":9,"./js/loader":10,"./js/template":11}],13:[function(require,module,exports){
'use strict';

module.exports = {
	get: request.bind(null, 'GET'),
	post: request.bind(null, 'POST')
};

/**
 * ajax.get(url, callback)
 * ajax.post(url, callback)
 * 
 * `url` can be a string, or an object containing a 'url' string property.
 * 
 * If you specify an object, the following options can also be specified:
 * - headers {Object}		- A hash of key-value pairs to use as headers.
 * - body {String or JSON-serializable data}
 * - username {String}
 * - password {String}
 * - timeout {Number}		- Timeout delay in milliseconds.
 * 
 * Content-Type header is automatically added.
 * 
 * `callback` is a function with the following signature:
 * function (err, response, xhr)
 * 		`err` is either null, TypeError, or Error with a 'reason' property,
 * 		which could either be 'network', 'timeout', or 'http'.
 * 		
 * 		`response` is either a string response, or the JSON-parsed response,
 * 		depending on the responses 'Content-Type' header.
 * 		
 * 		`xhr` is the original XMLHttpRequest object.
 * 		
 * 		If `err` is not null, the second and third arguments are undefined.
 */
function request(method, options, cb) {
	var body,
		headers,
		resolved = false,
		xhr = new XMLHttpRequest(),
		args = [method, '', true, '', ''];
		
	if (typeof options === 'string') {
		args[1] = options;
	} else if (options && typeof options === 'object') {
		args[1] = options.url;
		headers = options.headers;
		body = options.body;
		if (options.username != null) {args[3] = '' + options.username};
		if (options.password != null) {args[4] = '' + options.password};
	}
	if (!args[1] || typeof args[1] !== 'string') {
		return cb(new TypeError('invalid url provided.'));
	}
	
	xhr.open.apply(xhr, args);
	xhr.timeout = ~~options.timeout;
	if (headers && typeof headers === 'object') {
		for (var headerKey in headers) {
			xhr.setRequestHeader(headerKey, headers[headerKey]);
		}
	}
	
	if (typeof cb === 'function') {
		xhr.onerror = function () {
			if (!resolved) {
				resolved = true;
				var err = new Error('A connection could not be made.');
				err.reason = 'network';
				cb(err);
			}
		};
		xhr.ontimeout = function () {
			if (!resolved) {
				resolved = true;
				var err = new Error('The network timed out.');
				err.reason = 'timeout';
				cb(err);
			}
		};
		xhr.onload = function () {
			if (!resolved) {
				resolved = true;
				if (xhr.status >= 400) {
					var err = new Error(xhr.statusText);
					err.reason = 'http';
					cb(err);
				} else {
					var responseData = deserializeBody(xhr.response, xhr);
					if (responseData instanceof Error) {
						cb(responseData);
					} else {
						cb(null, responseData, xhr);
					}
				}
			}
		};
	}
	
	var bodyData = serializeBody(body, xhr);
	if (bodyData instanceof Error) {
		xhr.abort();
		return cb(bodyData);
	}
	
	xhr.send(bodyData);
}

function serializeBody(data, xhr) {
	if (typeof data === 'string' || data === undefined) {
		xhr.setRequestHeader('Content-Type', 'text/plain');
		return data || '';
	} else {
		try {
			var json = JSON.stringify(data);
			xhr.setRequestHeader('Content-Type', 'application/json');
			return json;
		} catch (err) {
			return new TypeError('Could not serialize request body into JSON.');
		}
	}
}

function deserializeBody(data, xhr) {
	var contentType = xhr.getResponseHeader('Content-Type') || '';
	if (/\bapplication\/json\b/i.test(contentType)) {
		try {
			return JSON.parse(data);
		} catch (err) {
			return new TypeError('Could not deserialize JSON response body.');
		}
	}
	return '' + data;
}

},{}],14:[function(require,module,exports){
'use strict';

var limber = require('limber');
var ajax = require('ajax');
var formValidation = require('../../api/form-validation');

module.exports = function () {
	function resize() {
		positionBackNav();
		positionContactForm();
	}
	resize();
	window.addEventListener('resize', resize);
	setTimeout(resize, 0);
	
	activateForm();
};

var clickedRecaptcha = false;
window.recaptchaCallback = function () {
	clickedRecaptcha = true;
};
var captcha = document.querySelector('.g-recaptcha');

function positionBackNav() {
	var backNav = document.querySelector('nav.back');
	
	// Find last element before nav.social-nav.
	var main = document.querySelector('main'),
		topEl = main.querySelector('section').firstElementChild,
		socialNav = document.querySelector('nav.social-nav');
	while (topEl.nextElementSibling && topEl.nextElementSibling !== socialNav) {
		topEl = topEl.nextElementSibling;
	}
	
	// Get bottom of last element, and top of nav.social-nav.
	var top = topEl.offsetTop + topEl.offsetHeight;
	var bottom = socialNav.offsetTop + socialNav.querySelector('li').offsetTop;
	
	// Position nav.back
	if (limber.mq.booklet) {
		backNav.style.top = top + main.offsetTop + (bottom - top)/2 - backNav.offsetHeight/2 - 5 + 'px';
	} else {
		var socialNavHeight = socialNav.querySelector('li').offsetHeight;
		backNav.style.top = main.offsetTop + bottom - backNav.offsetHeight - socialNavHeight + 'px';
	}
	
	// Inline text with main flow.
	backNav.querySelector('a').style.paddingLeft = main.offsetLeft + 'px';
}

function positionContactForm() {
	var form = document.querySelector('body.contact form');
	
	var firstP = document.querySelector('main section').firstElementChild;
	while (firstP.nextElementSibling && firstP.tagName !== 'P') {
		firstP = firstP.nextElementSibling;
	}
	
	form.style.top = firstP.offsetTop + 'px';
}

function activateForm() {
	// Requires a <form>, <output>, and [type="submit"].
	
	var disabled = false;
	var form = document.querySelector('form');
	var inputs = [].slice.call(form.querySelectorAll('input[type="text"], textarea'))
				.filter(function (el) {return formValidation[el.name];});
	var singleRowTextareas = [].slice.call(form.querySelectorAll('textarea[rows="1"]'));
	var submit = form.querySelector('[type="submit"]');
	var output = form.querySelector('output');
	
	// Trigger checkValid and checkInvalid on input interaction.
	setTimeout(function () {
		inputs.forEach(function (el) {
			var timer = null;
			el.addEventListener('input', function () {
				checkValid(el);
				timer && clearTimeout(timer);
				timer = setTimeout(timeoutCheck, 1500);
			});
			el.addEventListener('blur', timeoutCheck);
			function timeoutCheck() {
				checkInvalid(el);
				clearTimeout(timer);
				timer = null;
			}
		});
	}, 0);
	
	// Auto-sizing of single-row textarea elements.
	singleRowTextareas.forEach(function (el) {
		el.addEventListener('input', function () {
			updateTextareaRows(el);
			positionBackNav();
		});
	});
	
	// AJAX form submittion.
	form.addEventListener('submit', function (ev) {
		ev.preventDefault();
		if (disabled) {return;}
		
		inputs.forEach(checkValid);
		if (allValid() && clickedRecaptcha) {
			disableForm();
			captcha.classList.add('with-output');
			output.setAttribute('data-state', 'pending');
			output.innerHTML = 'Processing...';
			ajax[form.method.toLowerCase()]({
				url: form.action,
				timeout: 15000,
				body: getFormData()
			}, response);
		} else {
			inputs.forEach(checkInvalid);
		}
	});
	
	function checkValid(el) {
		var valid = formValidation[el.name].test(el.value);
		el.classList[valid ? 'add' : 'remove']('valid');
		valid && el.classList.remove('invalid');
		updateSubmitButton();
		output.innerHTML = '';
		captcha.classList.remove('with-output');
	}
	function checkInvalid(el) {
		var valid = formValidation[el.name].test(el.value);
		valid || el.classList.add('invalid');
		updateSubmitButton();
	}
	function allValid() {
		return form.querySelectorAll('.valid').length === inputs.length;
	}
	
	function updateTextareaRows(el) {
		var changed = false;
		var rows = +el.getAttribute('rows');
		while (el.scrollHeight > el.clientHeight && rows < 50) {
			el.setAttribute('rows', ++rows);
			changed = true;
		}
		if (!changed) {
			do {
				el.setAttribute('rows', --rows);
			} while ((el.scrollHeight <= el.clientHeight) && rows > 0)
			var notEnoughRows = el.scrollHeight > el.clientHeight || rows === 0;
			el.setAttribute('rows', rows + +notEnoughRows);
		}
	}
	
	function getFormData() {
		var data = {};
		[].slice.call(form.elements).forEach(function (el) {
			if (el.name) {
				data[el.name] = el.value;
			}
		});
		return data;
	}
	
	var errorMessages = {
		timeout: 'The network timed out. Check your internet connection, or try again later.',
		network: 'A connection could not be made. Check your internet connection, or try again later.',
		http: 'The email could not be sent. Please try again later.'
	};
	function response(err, response, xhr) {
		captcha.classList.add('with-output');
		if (err) {
			enableForm();
			output.setAttribute('data-state', 'failure');
			output.innerHTML = errorMessages[err.type] || 'Processing error.';
		} else {
			resetForm();
			enableForm();
			output.setAttribute('data-state', 'success');
			output.innerHTML = 'Success! Thanks for getting in touch. You\'ll hear from me soon!';
		}
	}
	
	function resetForm() {
		inputs.forEach(function (el) {
			el.value = '';
			el.classList.remove('valid');
			el.classList.remove('invalid');
		});
		singleRowTextareas.forEach(function (el) {
			el.setAttribute('rows', 1);
		});
	}
	function disableForm() {
		disabled = true;
		inputs.forEach(function (el) {el.setAttribute('disabled', '');});
		submit.setAttribute('disabled', '');
	}
	function enableForm() {
		disabled = false;
		inputs.forEach(function (el) {el.removeAttribute('disabled');});
		updateSubmitButton();
	}
	function updateSubmitButton() {
		allValid() && clickedRecaptcha
			? submit.removeAttribute('disabled')
			: submit.setAttribute('disabled', '');
	}
	
	window.recaptchaCallback = function () {
		clickedRecaptcha = true;
		updateSubmitButton();
	};
	
	// Initial state.
	inputs.forEach(function (el) {
		if (el.value) {
			checkValid(el);
			checkInvalid(el);
		}
	});
	singleRowTextareas.forEach(updateTextareaRows);
	updateSubmitButton();
}

},{"../../api/form-validation":1,"ajax":13,"limber":8}],15:[function(require,module,exports){
'use strict';

var limber = require('limber');
var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makeSoundcloud = require('make-soundcloud');
var makeVideoBox = require('make-video-box');
var makePlaylistBox = require('make-playlist-box');

module.exports = function () {
	ajax.get('/api/featured', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.featuredTrack
				&& contentArea.appendChild(makeSoundcloud(data.featuredTrack.embedUri));
			data.featuredVideo
				&& contentArea.appendChild(makeVideoBox(data.featuredVideo));
			data.featuredPlaylist
				&& contentArea.appendChild(makePlaylistBox(data.featuredPlaylist));
			
			slideInChildren(contentArea);
		}
	});
};

},{"ajax":13,"limber":8,"make-playlist-box":16,"make-soundcloud":17,"make-video-box":19,"slide-in-children":24}],16:[function(require,module,exports){
'use strict';
// Playlist: title, details, link, artwork, tracks
// Track: title, embedUri

var makeTruncatedBox = require('make-truncated-box');
var makeSoundcloud = require('make-soundcloud');

module.exports = function makePlaylistBox(playlist) {
	var playlistBox = document.createElement('div');
	playlistBox.classList.add('playlist-box');
	
	if (playlist.artwork) {
		var artworkLink = document.createElement('a');
		artworkLink.classList.add('alt-link');
		artworkLink.setAttribute('href', playlist.link);
		artworkLink.setAttribute('target', '_blank');
		playlistBox.appendChild(artworkLink);
		
		var artwork = document.createElement('img');
		artwork.classList.add('artwork');
		artwork.setAttribute('src', playlist.artwork);
		artworkLink.appendChild(artwork);
	}
	
	var title = document.createElement('h6');
	playlistBox.appendChild(title);
	
	var link = document.createElement('a');
	link.classList.add('alt-link');
	link.setAttribute('href', playlist.link);
	link.setAttribute('target', '_blank');
	link.textContent = playlist.title;
	title.appendChild(link);
	
	playlistBox.appendChild(makeTruncatedBox(playlist.details));
	
	playlist.tracks.forEach(function (track) {
		playlistBox.appendChild(makeTrackBox(track));
	});
	
	return playlistBox;
};

function makeTrackBox(track) {
	var widget;
	var trackBox = document.createElement('div');
	trackBox.classList.add('track-box');
	
	var title = document.createElement('a');
	title.classList.add('icon-play-circle-o');
	title.setAttribute('href', 'javascript:void(0)');
	title.textContent = track.title;
	trackBox.appendChild(title);
	
	var iframeHolder = document.createElement('div');
	iframeHolder.classList.add('iframe-holder');
	trackBox.appendChild(iframeHolder);
	
	title.addEventListener('click', function () {
		[].slice.call(document.querySelectorAll('.track-box')).forEach(function (tb) {
			trackBox === tb || tb.classList.remove('expanded');
		});
		var expanded = trackBox.classList.toggle('expanded');
		if (!trackBox.querySelector('iframe')) {
			var iframe = makeSoundcloud(track.embedUri, null, true);
			iframeHolder.appendChild(iframe);
			widget = SC.Widget(iframe);
			widget.bind(SC.Widget.Events.READY, function () {
				trackBox.classList.contains('expanded') ? widget.play() : widget.pause();
			});
		} else {
			expanded ? widget.play() : widget.pause();
		}
	});
	
	return trackBox;
}

},{"make-soundcloud":17,"make-truncated-box":18}],17:[function(require,module,exports){
'use strict';

/**
 * Example:
 * var uri = 'http://api.soundcloud.com/tracks/77937944';
 * document.querySelector('main').appendChild(makeSoundcloud(uri));
 */
module.exports = function (uri, cb, autoplay) {
	var options = [
		'auto_play=' + (autoplay ? 'true' : 'false'),
		'hide_related=true',
		'show_comments=true',
		'show_user=true',
		'show_reposts=false',
		'visual=false',
		'color=5bc6f1',
		'theme_color=f5f5f5',
		'buying=false',
		'download=false',
		'sharing=false',
		'liking=true',
		'show_playcount=false',
		'show_artwork=false'
	].join('&');
	var src = 'https://w.soundcloud.com/player/?url=' + encodeURIComponent(uri);
	
	var iframe = document.createElement('iframe');
	iframe.setAttribute('scrolling', 'no');
	iframe.setAttribute('frameborder', 'no');
	iframe.setAttribute('src', src + '&' + options);
	cb && iframe.addEventListener('load', cb);
	return iframe;
};

},{}],18:[function(require,module,exports){
'use strict';

var limber = require('limber');
var scrollTo = require('scroll-to');
var processText = require('process-text');

module.exports = function makeTruncatedBox(text) {
	var box = document.createElement('div');
	box.classList.add('truncated-box');
	
	var paragraph = document.createElement('p');
	paragraph.innerHTML = processText(text);
	box.appendChild(paragraph);
	
	var readMore = document.createElement('a');
	readMore.classList.add('read-more');
	readMore.setAttribute('href', 'javascript:void(0)');
	box.appendChild(readMore);
	setReadMore();
	
	function setReadMore() {
		readMore.classList.remove('icon-circle-o');
		readMore.classList.add('icon-plus');
		readMore.textContent = ' read more';
	}
	function setReadLess() {
		readMore.classList.remove('icon-plus');
		readMore.classList.add('icon-circle-o');
		readMore.textContent = ' read less';
	}
	
	readMore.addEventListener('click', function () {
		if (box.classList.toggle('expanded')) {
			paragraph.style.maxHeight = paragraph.scrollHeight + 'px';
			setReadLess();
			
			var contentArea = document.querySelector('.content-area');
			var top = box.parentElement.offsetTop + contentArea.offsetTop + document.querySelector('main').offsetTop;
			var contentMargin = contentArea.firstElementChild.offsetTop;
			scrollTo(document.querySelector('#frame'),
				limber.mq.phone
					? top - contentMargin/2 + document.querySelectorAll('section')[1].offsetTop
					: top - contentMargin/2);
		} else {
			paragraph.style.maxHeight = '';
			setReadMore();
		}
	});
	
	function testReadMoreNeeded() {
		var originalMaxHeight = paragraph.style.maxHeight;
		var originalTransition = paragraph.style.transition;
		paragraph.style.transition = 'none';
		paragraph.clientHeight; // force reflow
		paragraph.style.maxHeight = '';
		paragraph.clientHeight; // force reflow
		if (paragraph.scrollHeight <= paragraph.clientHeight) {
			box.classList.add('no-overflow');
		} else {
			box.classList.remove('no-overflow');
		}
		paragraph.style.maxHeight = originalMaxHeight;
		paragraph.style.transition = originalTransition;
		paragraph.clientHeight; // force reflow
	}
	setTimeout(testReadMoreNeeded, 0);
	window.addEventListener('resize', function () {
		testReadMoreNeeded();
		if (box.classList.contains('expanded')) {
			paragraph.style.maxHeight = paragraph.scrollHeight + 'px';
		}
	});
	
	return box;
};

},{"limber":8,"process-text":22,"scroll-to":23}],19:[function(require,module,exports){
'use strict';
// Video: title, details, id, artwork

var limber = require('limber');
var makeTruncatedBox = require('make-truncated-box');
var makeYoutube = require('make-youtube');

module.exports = function makeVideoBox(video) {
	var externalUrl = 'http://youtube.com/watch?v=' + encodeURIComponent(video.id);
	
	var videoBox = document.createElement('div');
	videoBox.classList.add('video-box');
	
	if (video.artwork) {
		var artworkLink = document.createElement('a');
		artworkLink.classList.add('alt-link');
		artworkLink.setAttribute('href', externalUrl);
		artworkLink.setAttribute('target', '_blank');
		videoBox.appendChild(artworkLink);
		
		var artwork = document.createElement('img');
		artwork.classList.add('artwork');
		artwork.setAttribute('src', video.artwork);
		artworkLink.appendChild(artwork);
	}
	
	var title = document.createElement('h6');
	videoBox.appendChild(title);
	
	var link = document.createElement('a');
	link.classList.add('alt-link');
	link.setAttribute('href', externalUrl);
	link.setAttribute('target', '_blank');
	link.textContent = video.title;
	title.appendChild(link);
	
	videoBox.appendChild(makeTruncatedBox(video.details));
	
	videoBox.appendChild(makePlayBox(video.id));
	
	return videoBox;
};

function makePlayBox(id) {
	var playBox = document.createElement('div');
	playBox.classList.add('play-box');
	
	var link = document.createElement('a');
	link.classList.add('icon-play-circle-o');
	link.setAttribute('href', 'javascript:void(0)');
	link.textContent = 'Watch Video';
	playBox.appendChild(link);
	
	link.addEventListener('click', function () {
		if (limber.mq.booklet) {
			var popup = document.querySelector('#popup');
			if (!popup.classList.contains('active')) {
				popup.appendChild(makeYoutube(id, null, true));
				document.querySelector('#container').classList.add('blur');
				popup.classList.add('active');
			}
		} else {
			window.open('http://youtube.com/watch?v=' + id, '_blank');
		}
	});
	
	return playBox;
}

},{"limber":8,"make-truncated-box":18,"make-youtube":20}],20:[function(require,module,exports){
'use strict';

/**
 * Example:
 * var id = 'UsaJ7ZvCjmY';
 * document.querySelector('main').appendChild(makeYoutube(id));
 */
module.exports = function (id, cb, autoplay) {
	var options = [
		'autoplay=' + (autoplay ? '1' : '0'),
		'fs=0'
	].join('&');
	var src = 'http://www.youtube.com/embed/' + encodeURIComponent(id);
	
	var iframe = document.createElement('iframe');
	iframe.setAttribute('scrolling', 'no')
	iframe.setAttribute('frameborder', 'no')
	iframe.setAttribute('src', src + '?' + options);
	cb && iframe.addEventListener('load', cb);
	return iframe;
};

},{}],21:[function(require,module,exports){
'use strict';

var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makePlaylistBox = require('make-playlist-box');

module.exports = function () {
	ajax.get('/api/music', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.playlists.forEach(function (playlist) {
				contentArea.appendChild(makePlaylistBox(playlist));
			});
			
			slideInChildren(contentArea);
		}
	});
};

},{"ajax":13,"make-playlist-box":16,"slide-in-children":24}],22:[function(require,module,exports){
'use strict';

module.exports = function (text) {
	return escapeHtml(text
		.replace(/\ufffd/g, '')
		.replace(/\b[-\w.]+@\w[-\w.]*?\.(co\.uk|com|net|co|org|int|edu|mil|gov|us|me|io|uk|ninja|be|gl|do|tv|cc|de|es|eu|fr|gb|gg|gy|ie|is|it|jp|kr|mt|my|na|no|pe|pm|so|ss|to|ca|mx)\b/gi,
			function (match) {
				return '\ufffd<a href="mailto:' + encodeURI(match) + '">' + escapeHtml(match) + '</a>\ufffd';
			})
		.replace(/\ufffd[\s\S]*?\ufffd|\b(https?:\/\/)?(\w[-\w.]*?\.(co\.uk|com|net|co|org|int|edu|mil|gov|us|me|io|uk|ninja|be|gl|do|tv|cc|de|es|eu|fr|gb|gg|gy|ie|is|it|jp|kr|mt|my|na|no|pe|pm|so|ss|to|ca|mx)\b((\/|\?)(\.?[-\/\w?&=+!~#$%^])*)?)/gi,
			function (match, p1, p2) {
				if (match[0] === '\ufffd') {
					return match;
				}
				var href = (p1 || 'http://') + p2;
				return '\ufffd<a href="' + encodeURI(href) + '">' + escapeHtml(match) + '</a>\ufffd';
			}));
};

function escapeHtml(text) {
	return text.replace(/\ufffd[\s\S]*?\ufffd|<|>|&|'|"/g, function (match) {
		if (match[0] === '\ufffd') {
			return match.replace(/\ufffd/g, '');
		}
		switch (match.charCodeAt(0)) {
			case 34: return '&quot;';	// "
			case 38: return '&amp;';	// &
			case 39: return '&#39;';	// '
			case 60: return '&lt;';		// <
			case 62: return '&gt;';		// >
		}
		return '';
	});
}

},{}],23:[function(require,module,exports){
/*! source: https://gist.github.com/james2doyle/5694700 */
'use strict';

module.exports = function scrollTo(el, to, cb) {
	var start = el.scrollTop,
		change = to - start,
		progress = 0,
		duration = 500,
		startTime = Date.now();
	function animateScroll() {
		progress = Date.now() - startTime;
		el.scrollTop = easing(progress, start, change, duration);
		
		if (progress < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			cb && cb.call(el);
		}
	}
	animateScroll();
};

function easing(t, b, c, d) {
	var ts=(t/=d)*t;
	var tc=ts*t;
	return b+c*(-1.6525*tc*ts + 5.9575*ts*ts + -7.505*tc + 2.8*ts + 1.4*t);
}

},{}],24:[function(require,module,exports){
'use strict';

var limber = require('limber');

module.exports = function (parent) {
	limber.loader.progress === 100
		? slideInChildren(parent)
		: limber.loader.on('load', slideInChildren.bind(null, parent));
};

function slideInChildren(parent) {
	var children = [].slice.call(parent.children);
	var styles = children.map(function (el) {return el.style;});
	var len = styles.length;
	
	var height = parent.scrollHeight;
	var time = Math.min(Math.round(height / 1.428) || 0, 2000);
	
	var transform = Modernizr.prefixed('transform');
	var startValue = Modernizr.prefixedCSSValue(transform, 'translateY(-' + height + 'px)');
	var endValue =  Modernizr.prefixedCSSValue(transform, 'translateY(0px)');
	var transitionValue = [Modernizr.prefixedCSS('transform'), time + 'ms', 'ease-in-out'].join(' ');
	
	for (var i=0; i<len; i++) styles[i].transition = 'none';
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) styles[i][transform] = startValue;
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) styles[i].transition = transitionValue;
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) {
		styles[i][transform] = endValue;
		children[i].addEventListener('transitionend', transitionend);
	}
	
	function transitionend(ev) {
		if (ev.eventPhase === ev.AT_TARGET) {
			this.style[transform] = '';
			this.style.transition = '';
			this.style.willChange = 'initial';
			this.offsetHeight; // force reflow
		}
	}
}



},{"limber":8}],25:[function(require,module,exports){
'use strict';

var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makeVideoBox = require('make-video-box');

module.exports = function () {
	ajax.get('/api/sound-design', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.videos.forEach(function (video) {
				contentArea.appendChild(makeVideoBox(video));
			});
			
			slideInChildren(contentArea);
		}
	});
};

},{"ajax":13,"make-video-box":19,"slide-in-children":24}]},{},[7])

