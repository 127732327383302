'use strict';

var limber = require('limber');
var ajax = require('ajax');
var formValidation = require('../../api/form-validation');

module.exports = function () {
	function resize() {
		positionBackNav();
		positionContactForm();
	}
	resize();
	window.addEventListener('resize', resize);
	setTimeout(resize, 0);
	
	activateForm();
};

var clickedRecaptcha = false;
window.recaptchaCallback = function () {
	clickedRecaptcha = true;
};
var captcha = document.querySelector('.g-recaptcha');

function positionBackNav() {
	var backNav = document.querySelector('nav.back');
	
	// Find last element before nav.social-nav.
	var main = document.querySelector('main'),
		topEl = main.querySelector('section').firstElementChild,
		socialNav = document.querySelector('nav.social-nav');
	while (topEl.nextElementSibling && topEl.nextElementSibling !== socialNav) {
		topEl = topEl.nextElementSibling;
	}
	
	// Get bottom of last element, and top of nav.social-nav.
	var top = topEl.offsetTop + topEl.offsetHeight;
	var bottom = socialNav.offsetTop + socialNav.querySelector('li').offsetTop;
	
	// Position nav.back
	if (limber.mq.booklet) {
		backNav.style.top = top + main.offsetTop + (bottom - top)/2 - backNav.offsetHeight/2 - 5 + 'px';
	} else {
		var socialNavHeight = socialNav.querySelector('li').offsetHeight;
		backNav.style.top = main.offsetTop + bottom - backNav.offsetHeight - socialNavHeight + 'px';
	}
	
	// Inline text with main flow.
	backNav.querySelector('a').style.paddingLeft = main.offsetLeft + 'px';
}

function positionContactForm() {
	var form = document.querySelector('body.contact form');
	
	var firstP = document.querySelector('main section').firstElementChild;
	while (firstP.nextElementSibling && firstP.tagName !== 'P') {
		firstP = firstP.nextElementSibling;
	}
	
	form.style.top = firstP.offsetTop + 'px';
}

function activateForm() {
	// Requires a <form>, <output>, and [type="submit"].
	
	var disabled = false;
	var form = document.querySelector('form');
	var inputs = [].slice.call(form.querySelectorAll('input[type="text"], textarea'))
				.filter(function (el) {return formValidation[el.name];});
	var singleRowTextareas = [].slice.call(form.querySelectorAll('textarea[rows="1"]'));
	var submit = form.querySelector('[type="submit"]');
	var output = form.querySelector('output');
	
	// Trigger checkValid and checkInvalid on input interaction.
	setTimeout(function () {
		inputs.forEach(function (el) {
			var timer = null;
			el.addEventListener('input', function () {
				checkValid(el);
				timer && clearTimeout(timer);
				timer = setTimeout(timeoutCheck, 1500);
			});
			el.addEventListener('blur', timeoutCheck);
			function timeoutCheck() {
				checkInvalid(el);
				clearTimeout(timer);
				timer = null;
			}
		});
	}, 0);
	
	// Auto-sizing of single-row textarea elements.
	singleRowTextareas.forEach(function (el) {
		el.addEventListener('input', function () {
			updateTextareaRows(el);
			positionBackNav();
		});
	});
	
	// AJAX form submittion.
	form.addEventListener('submit', function (ev) {
		ev.preventDefault();
		if (disabled) {return;}
		
		inputs.forEach(checkValid);
		if (allValid() && clickedRecaptcha) {
			disableForm();
			captcha.classList.add('with-output');
			output.setAttribute('data-state', 'pending');
			output.innerHTML = 'Processing...';
			ajax[form.method.toLowerCase()]({
				url: form.action,
				timeout: 15000,
				body: getFormData()
			}, response);
		} else {
			inputs.forEach(checkInvalid);
		}
	});
	
	function checkValid(el) {
		var valid = formValidation[el.name].test(el.value);
		el.classList[valid ? 'add' : 'remove']('valid');
		valid && el.classList.remove('invalid');
		updateSubmitButton();
		output.innerHTML = '';
		captcha.classList.remove('with-output');
	}
	function checkInvalid(el) {
		var valid = formValidation[el.name].test(el.value);
		valid || el.classList.add('invalid');
		updateSubmitButton();
	}
	function allValid() {
		return form.querySelectorAll('.valid').length === inputs.length;
	}
	
	function updateTextareaRows(el) {
		var changed = false;
		var rows = +el.getAttribute('rows');
		while (el.scrollHeight > el.clientHeight && rows < 50) {
			el.setAttribute('rows', ++rows);
			changed = true;
		}
		if (!changed) {
			do {
				el.setAttribute('rows', --rows);
			} while ((el.scrollHeight <= el.clientHeight) && rows > 0)
			var notEnoughRows = el.scrollHeight > el.clientHeight || rows === 0;
			el.setAttribute('rows', rows + +notEnoughRows);
		}
	}
	
	function getFormData() {
		var data = {};
		[].slice.call(form.elements).forEach(function (el) {
			if (el.name) {
				data[el.name] = el.value;
			}
		});
		return data;
	}
	
	var errorMessages = {
		timeout: 'The network timed out. Check your internet connection, or try again later.',
		network: 'A connection could not be made. Check your internet connection, or try again later.',
		http: 'The email could not be sent. Please try again later.'
	};
	function response(err, response, xhr) {
		captcha.classList.add('with-output');
		if (err) {
			enableForm();
			output.setAttribute('data-state', 'failure');
			output.innerHTML = errorMessages[err.type] || 'Processing error.';
		} else {
			resetForm();
			enableForm();
			output.setAttribute('data-state', 'success');
			output.innerHTML = 'Success! Thanks for getting in touch. You\'ll hear from me soon!';
		}
	}
	
	function resetForm() {
		inputs.forEach(function (el) {
			el.value = '';
			el.classList.remove('valid');
			el.classList.remove('invalid');
		});
		singleRowTextareas.forEach(function (el) {
			el.setAttribute('rows', 1);
		});
	}
	function disableForm() {
		disabled = true;
		inputs.forEach(function (el) {el.setAttribute('disabled', '');});
		submit.setAttribute('disabled', '');
	}
	function enableForm() {
		disabled = false;
		inputs.forEach(function (el) {el.removeAttribute('disabled');});
		updateSubmitButton();
	}
	function updateSubmitButton() {
		allValid() && clickedRecaptcha
			? submit.removeAttribute('disabled')
			: submit.setAttribute('disabled', '');
	}
	
	window.recaptchaCallback = function () {
		clickedRecaptcha = true;
		updateSubmitButton();
	};
	
	// Initial state.
	inputs.forEach(function (el) {
		if (el.value) {
			checkValid(el);
			checkInvalid(el);
		}
	});
	singleRowTextareas.forEach(updateTextareaRows);
	updateSubmitButton();
}
