'use strict';

var limber = require('limber');

module.exports = function (parent) {
	limber.loader.progress === 100
		? slideInChildren(parent)
		: limber.loader.on('load', slideInChildren.bind(null, parent));
};

function slideInChildren(parent) {
	var children = [].slice.call(parent.children);
	var styles = children.map(function (el) {return el.style;});
	var len = styles.length;
	
	var height = parent.scrollHeight;
	var time = Math.min(Math.round(height / 1.428) || 0, 2000);
	
	var transform = Modernizr.prefixed('transform');
	var startValue = Modernizr.prefixedCSSValue(transform, 'translateY(-' + height + 'px)');
	var endValue =  Modernizr.prefixedCSSValue(transform, 'translateY(0px)');
	var transitionValue = [Modernizr.prefixedCSS('transform'), time + 'ms', 'ease-in-out'].join(' ');
	
	for (var i=0; i<len; i++) styles[i].transition = 'none';
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) styles[i][transform] = startValue;
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) styles[i].transition = transitionValue;
	parent.offsetHeight; // force reflow
	
	for (var i=0; i<len; i++) {
		styles[i][transform] = endValue;
		children[i].addEventListener('transitionend', transitionend);
	}
	
	function transitionend(ev) {
		if (ev.eventPhase === ev.AT_TARGET) {
			this.style[transform] = '';
			this.style.transition = '';
			this.style.willChange = 'initial';
			this.offsetHeight; // force reflow
		}
	}
}


