'use strict';

var ajax = require('ajax');
var slideInChildren = require('slide-in-children');
var makePlaylistBox = require('make-playlist-box');

module.exports = function () {
	ajax.get('/api/music', function (err, data) {
		if (err) {
			console.error(err);
		} else {
			var contentArea = document.querySelector('.content-area');
			data.playlists.forEach(function (playlist) {
				contentArea.appendChild(makePlaylistBox(playlist));
			});
			
			slideInChildren(contentArea);
		}
	});
};
