'use strict';
// Playlist: title, details, link, artwork, tracks
// Track: title, embedUri

var makeTruncatedBox = require('make-truncated-box');
var makeSoundcloud = require('make-soundcloud');

module.exports = function makePlaylistBox(playlist) {
	var playlistBox = document.createElement('div');
	playlistBox.classList.add('playlist-box');
	
	if (playlist.artwork) {
		var artworkLink = document.createElement('a');
		artworkLink.classList.add('alt-link');
		artworkLink.setAttribute('href', playlist.link);
		artworkLink.setAttribute('target', '_blank');
		playlistBox.appendChild(artworkLink);
		
		var artwork = document.createElement('img');
		artwork.classList.add('artwork');
		artwork.setAttribute('src', playlist.artwork);
		artworkLink.appendChild(artwork);
	}
	
	var title = document.createElement('h6');
	playlistBox.appendChild(title);
	
	var link = document.createElement('a');
	link.classList.add('alt-link');
	link.setAttribute('href', playlist.link);
	link.setAttribute('target', '_blank');
	link.textContent = playlist.title;
	title.appendChild(link);
	
	playlistBox.appendChild(makeTruncatedBox(playlist.details));
	
	playlist.tracks.forEach(function (track) {
		playlistBox.appendChild(makeTrackBox(track));
	});
	
	return playlistBox;
};

function makeTrackBox(track) {
	var widget;
	var trackBox = document.createElement('div');
	trackBox.classList.add('track-box');
	
	var title = document.createElement('a');
	title.classList.add('icon-play-circle-o');
	title.setAttribute('href', 'javascript:void(0)');
	title.textContent = track.title;
	trackBox.appendChild(title);
	
	var iframeHolder = document.createElement('div');
	iframeHolder.classList.add('iframe-holder');
	trackBox.appendChild(iframeHolder);
	
	title.addEventListener('click', function () {
		[].slice.call(document.querySelectorAll('.track-box')).forEach(function (tb) {
			trackBox === tb || tb.classList.remove('expanded');
		});
		var expanded = trackBox.classList.toggle('expanded');
		if (!trackBox.querySelector('iframe')) {
			var iframe = makeSoundcloud(track.embedUri, null, true);
			iframeHolder.appendChild(iframe);
			widget = SC.Widget(iframe);
			widget.bind(SC.Widget.Events.READY, function () {
				trackBox.classList.contains('expanded') ? widget.play() : widget.pause();
			});
		} else {
			expanded ? widget.play() : widget.pause();
		}
	});
	
	return trackBox;
}
