'use strict';

/**
 * Example:
 * var uri = 'http://api.soundcloud.com/tracks/77937944';
 * document.querySelector('main').appendChild(makeSoundcloud(uri));
 */
module.exports = function (uri, cb, autoplay) {
	var options = [
		'auto_play=' + (autoplay ? 'true' : 'false'),
		'hide_related=true',
		'show_comments=true',
		'show_user=true',
		'show_reposts=false',
		'visual=false',
		'color=5bc6f1',
		'theme_color=f5f5f5',
		'buying=false',
		'download=false',
		'sharing=false',
		'liking=true',
		'show_playcount=false',
		'show_artwork=false'
	].join('&');
	var src = 'https://w.soundcloud.com/player/?url=' + encodeURIComponent(uri);
	
	var iframe = document.createElement('iframe');
	iframe.setAttribute('scrolling', 'no');
	iframe.setAttribute('frameborder', 'no');
	iframe.setAttribute('src', src + '&' + options);
	cb && iframe.addEventListener('load', cb);
	return iframe;
};
