'use strict';
// Video: title, details, id, artwork

var limber = require('limber');
var makeTruncatedBox = require('make-truncated-box');
var makeYoutube = require('make-youtube');

module.exports = function makeVideoBox(video) {
	var externalUrl = 'http://youtube.com/watch?v=' + encodeURIComponent(video.id);
	
	var videoBox = document.createElement('div');
	videoBox.classList.add('video-box');
	
	if (video.artwork) {
		var artworkLink = document.createElement('a');
		artworkLink.classList.add('alt-link');
		artworkLink.setAttribute('href', externalUrl);
		artworkLink.setAttribute('target', '_blank');
		videoBox.appendChild(artworkLink);
		
		var artwork = document.createElement('img');
		artwork.classList.add('artwork');
		artwork.setAttribute('src', video.artwork);
		artworkLink.appendChild(artwork);
	}
	
	var title = document.createElement('h6');
	videoBox.appendChild(title);
	
	var link = document.createElement('a');
	link.classList.add('alt-link');
	link.setAttribute('href', externalUrl);
	link.setAttribute('target', '_blank');
	link.textContent = video.title;
	title.appendChild(link);
	
	videoBox.appendChild(makeTruncatedBox(video.details));
	
	videoBox.appendChild(makePlayBox(video.id));
	
	return videoBox;
};

function makePlayBox(id) {
	var playBox = document.createElement('div');
	playBox.classList.add('play-box');
	
	var link = document.createElement('a');
	link.classList.add('icon-play-circle-o');
	link.setAttribute('href', 'javascript:void(0)');
	link.textContent = 'Watch Video';
	playBox.appendChild(link);
	
	link.addEventListener('click', function () {
		if (limber.mq.booklet) {
			var popup = document.querySelector('#popup');
			if (!popup.classList.contains('active')) {
				popup.appendChild(makeYoutube(id, null, true));
				document.querySelector('#container').classList.add('blur');
				popup.classList.add('active');
			}
		} else {
			window.open('http://youtube.com/watch?v=' + id, '_blank');
		}
	});
	
	return playBox;
}
