'use strict';

module.exports = {
	get: request.bind(null, 'GET'),
	post: request.bind(null, 'POST')
};

/**
 * ajax.get(url, callback)
 * ajax.post(url, callback)
 * 
 * `url` can be a string, or an object containing a 'url' string property.
 * 
 * If you specify an object, the following options can also be specified:
 * - headers {Object}		- A hash of key-value pairs to use as headers.
 * - body {String or JSON-serializable data}
 * - username {String}
 * - password {String}
 * - timeout {Number}		- Timeout delay in milliseconds.
 * 
 * Content-Type header is automatically added.
 * 
 * `callback` is a function with the following signature:
 * function (err, response, xhr)
 * 		`err` is either null, TypeError, or Error with a 'reason' property,
 * 		which could either be 'network', 'timeout', or 'http'.
 * 		
 * 		`response` is either a string response, or the JSON-parsed response,
 * 		depending on the responses 'Content-Type' header.
 * 		
 * 		`xhr` is the original XMLHttpRequest object.
 * 		
 * 		If `err` is not null, the second and third arguments are undefined.
 */
function request(method, options, cb) {
	var body,
		headers,
		resolved = false,
		xhr = new XMLHttpRequest(),
		args = [method, '', true, '', ''];
		
	if (typeof options === 'string') {
		args[1] = options;
	} else if (options && typeof options === 'object') {
		args[1] = options.url;
		headers = options.headers;
		body = options.body;
		if (options.username != null) {args[3] = '' + options.username};
		if (options.password != null) {args[4] = '' + options.password};
	}
	if (!args[1] || typeof args[1] !== 'string') {
		return cb(new TypeError('invalid url provided.'));
	}
	
	xhr.open.apply(xhr, args);
	xhr.timeout = ~~options.timeout;
	if (headers && typeof headers === 'object') {
		for (var headerKey in headers) {
			xhr.setRequestHeader(headerKey, headers[headerKey]);
		}
	}
	
	if (typeof cb === 'function') {
		xhr.onerror = function () {
			if (!resolved) {
				resolved = true;
				var err = new Error('A connection could not be made.');
				err.reason = 'network';
				cb(err);
			}
		};
		xhr.ontimeout = function () {
			if (!resolved) {
				resolved = true;
				var err = new Error('The network timed out.');
				err.reason = 'timeout';
				cb(err);
			}
		};
		xhr.onload = function () {
			if (!resolved) {
				resolved = true;
				if (xhr.status >= 400) {
					var err = new Error(xhr.statusText);
					err.reason = 'http';
					cb(err);
				} else {
					var responseData = deserializeBody(xhr.response, xhr);
					if (responseData instanceof Error) {
						cb(responseData);
					} else {
						cb(null, responseData, xhr);
					}
				}
			}
		};
	}
	
	var bodyData = serializeBody(body, xhr);
	if (bodyData instanceof Error) {
		xhr.abort();
		return cb(bodyData);
	}
	
	xhr.send(bodyData);
}

function serializeBody(data, xhr) {
	if (typeof data === 'string' || data === undefined) {
		xhr.setRequestHeader('Content-Type', 'text/plain');
		return data || '';
	} else {
		try {
			var json = JSON.stringify(data);
			xhr.setRequestHeader('Content-Type', 'application/json');
			return json;
		} catch (err) {
			return new TypeError('Could not serialize request body into JSON.');
		}
	}
}

function deserializeBody(data, xhr) {
	var contentType = xhr.getResponseHeader('Content-Type') || '';
	if (/\bapplication\/json\b/i.test(contentType)) {
		try {
			return JSON.parse(data);
		} catch (err) {
			return new TypeError('Could not deserialize JSON response body.');
		}
	}
	return '' + data;
}
