/*! source: https://gist.github.com/james2doyle/5694700 */
'use strict';

module.exports = function scrollTo(el, to, cb) {
	var start = el.scrollTop,
		change = to - start,
		progress = 0,
		duration = 500,
		startTime = Date.now();
	function animateScroll() {
		progress = Date.now() - startTime;
		el.scrollTop = easing(progress, start, change, duration);
		
		if (progress < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			cb && cb.call(el);
		}
	}
	animateScroll();
};

function easing(t, b, c, d) {
	var ts=(t/=d)*t;
	var tc=ts*t;
	return b+c*(-1.6525*tc*ts + 5.9575*ts*ts + -7.505*tc + 2.8*ts + 1.4*t);
}
