'use strict';

var limber = require('limber');
var scrollTo = require('scroll-to');
var processText = require('process-text');

module.exports = function makeTruncatedBox(text) {
	var box = document.createElement('div');
	box.classList.add('truncated-box');
	
	var paragraph = document.createElement('p');
	paragraph.innerHTML = processText(text);
	box.appendChild(paragraph);
	
	var readMore = document.createElement('a');
	readMore.classList.add('read-more');
	readMore.setAttribute('href', 'javascript:void(0)');
	box.appendChild(readMore);
	setReadMore();
	
	function setReadMore() {
		readMore.classList.remove('icon-circle-o');
		readMore.classList.add('icon-plus');
		readMore.textContent = ' read more';
	}
	function setReadLess() {
		readMore.classList.remove('icon-plus');
		readMore.classList.add('icon-circle-o');
		readMore.textContent = ' read less';
	}
	
	readMore.addEventListener('click', function () {
		if (box.classList.toggle('expanded')) {
			paragraph.style.maxHeight = paragraph.scrollHeight + 'px';
			setReadLess();
			
			var contentArea = document.querySelector('.content-area');
			var top = box.parentElement.offsetTop + contentArea.offsetTop + document.querySelector('main').offsetTop;
			var contentMargin = contentArea.firstElementChild.offsetTop;
			scrollTo(document.querySelector('#frame'),
				limber.mq.phone
					? top - contentMargin/2 + document.querySelectorAll('section')[1].offsetTop
					: top - contentMargin/2);
		} else {
			paragraph.style.maxHeight = '';
			setReadMore();
		}
	});
	
	function testReadMoreNeeded() {
		var originalMaxHeight = paragraph.style.maxHeight;
		var originalTransition = paragraph.style.transition;
		paragraph.style.transition = 'none';
		paragraph.clientHeight; // force reflow
		paragraph.style.maxHeight = '';
		paragraph.clientHeight; // force reflow
		if (paragraph.scrollHeight <= paragraph.clientHeight) {
			box.classList.add('no-overflow');
		} else {
			box.classList.remove('no-overflow');
		}
		paragraph.style.maxHeight = originalMaxHeight;
		paragraph.style.transition = originalTransition;
		paragraph.clientHeight; // force reflow
	}
	setTimeout(testReadMoreNeeded, 0);
	window.addEventListener('resize', function () {
		testReadMoreNeeded();
		if (box.classList.contains('expanded')) {
			paragraph.style.maxHeight = paragraph.scrollHeight + 'px';
		}
	});
	
	return box;
};
